import axios from "axios";
import { MutationTree } from "vuex";
import { ActionTree } from "vuex";
import { RootState } from "./index";
import { VFormSelectOption, ShopManageRequest } from "../utils/interfaces";

import { API_BASE_URL, API_SHOP_LIST } from "../utils/constants";

export interface ShopManageState {
  response: ShopManageResponse;
}

export interface ShopManageResponse {
  status_cd: number;
  message: string;
  results: ShopManageResponseResult[];
}

export interface ShopManageResponseResult {
  total_count: number;
  display_page_start: number;
  display_page_end: number;
  items: ShopManageResponseResultItem[];
}

export interface ShopManageResponseResultItem {
  id: string;
  name: string;
  model_type: string;
  view_type: string;
}

const state: ShopManageState = {
  response: {
    status_cd: 200,
    message: "",
    results: [
      {
        total_count: 0,
        display_page_start: 0,
        display_page_end: 0,
        items: [
          {
            id: "",
            name: "",
            model_type: "",
            view_type: ""
          }
        ]
      }
    ]
  }
};

const getters = {};

const mutations: MutationTree<ShopManageState> = {
  setShopManageResponse(state: ShopManageState, payload: ShopManageResponse) {
    state.response = payload;
  }
};

const actions: ActionTree<ShopManageState, RootState> = {
  async fetchShopList(context, payload: ShopManageRequest) {
    const params = new URLSearchParams();
    for (const key in payload) {
      var value = payload[key];
      if (value.hasOwnProperty("value")) {
        const selectOption = value as VFormSelectOption;
        value = selectOption.value;
      }
      params.append(key, String(value));
    }

    const response = await axios.post(API_BASE_URL + API_SHOP_LIST, params);
    const responseData: ShopManageResponse = JSON.parse(
      JSON.stringify(response.data)
    ) as ShopManageResponse;
    context.commit("setShopManageResponse", responseData);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
