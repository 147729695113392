import Vue from "vue";
import Vuex from "vuex";
import ShopManage from "./shop-manage";

Vue.use(Vuex);

export interface RootState {}

export default new Vuex.Store<RootState>({
  mutations: {},
  actions: {},
  modules: {
    ShopManage
  }
});
