import Vue from "vue";
import Component from "vue-class-component";
import Admin from "@/store/admin/admin";
import UIAlert from "@/components/UIAlert.vue";
import { SideMenuItem, SubSideMenuItem } from "@/api/side-menu/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({ components: { UIAlert } })
export default class Container extends Vue {
  showDrawer1 = false;
  showDrawer2 = false;
  subMenusItem1 = [] as SubSideMenuItem[] | null;
  subMenusItem2 = [] as SubSideMenuItem[] | null;
  showingTitle = "";
  showLoginUserMenu = false;
  logoImagePath = null as string | null;
  businessId = 0 as number;

  get drawerItems() {
    return Admin.getMenuItems;
  }

  get showMenu() {
    return Admin.isSuccess && Admin.getLoginUserName;
  }

  get appName() {
    return Admin.getAppName;
  }

  get appIconUrl() {
    return Admin.getAppIconUrl;
  }

  get shopId() {
    return Admin.getShopId;
  }

  get shopName() {
    return Admin.getShopName;
  }

  get loginUserName() {
    return Admin.getLoginUserName;
  }

  get loginUserMenuIcon() {
    return this.showLoginUserMenu ? "close" : "menu";
  }

  get notifBadgeCount() {
    const items = Admin.getNotifMenuItem.items || [];
    return items.reduce((sum: number, cur: any) => {
      if (cur.badge) {
        return sum + cur.badge;
      } else {
        return sum;
      }
    }, 0);
  }

  get isAccountEditable() {
    return Admin.isAccountEditable;
  }
  async beforeCreate() {
    await Admin.restore();
  }

  async updated() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.logoImagePath = storage.logoImagePath ? storage.logoImagePath : null;
    this.businessId = storage.id ? storage.id : 0;
  }

  didClickDrawer(title: string) {
    let target = this.drawerItems.find(drawer => {
      return drawer.title === title;
    });
    if (!target) {
      target = Admin.getNotifMenuItem;
    }

    if (target) {
      if (target.to != null) {
        this.$router.push(target.to).then();
        return;
      }

      const isSameMenuClicked = this.showingTitle === title;
      const isSubMenuOpened = this.showDrawer1 || this.showDrawer2;
      if (isSameMenuClicked && isSubMenuOpened) {
        this.showDrawer1 = false;
        this.showDrawer2 = false;
        this.$nextTick(() => this.scrollSubDrawer());
        return;
      }

      this.showingTitle = title;
      if (this.showDrawer1) {
        this.showDrawer1 = false;
        this.showDrawer2 = true;
        this.subMenusItem2 = target.items;
      } else {
        this.showDrawer2 = false;
        this.showDrawer1 = true;
        this.subMenusItem1 = target.items;
      }
      this.$nextTick(() => this.scrollSubDrawer());
    }
  }

  menuIconPath(menuGroupId: number) {
    return `/images/menu/${menuGroupId}.svg`;
  }

  private scrollSubDrawer() {
    document.querySelectorAll("aside.sub-drawer").forEach(element => {
      (element as HTMLElement).style.top = `${window.pageYOffset}px`;
    });
  }

  async editAccount() {
    await this.$router.push({
      name: "login-members-edit",
      params: { id: String(Admin.getLoginUserId) }
    });
  }
  moveFAQ() {
    const url= "https://linktr.ee/uplink_uestion"
      window.open(url, '_blank')
  }
  async logout() {
    await Admin.logout();
    if (Admin.isSuccess) {
      // 管理ツールアプリ対応
      let userAgent = window.navigator.userAgent;
      if (userAgent.match(/newup_admin_tool_ios/) || userAgent.match(/newup_admin_tool_android/)) {
        alert(JSON.stringify({
          _vue_param: true,
          logout:true
        }));
      }

      await this.$router.push("/login");
    } else {
      await Flash.setErrorNow({
        message: Admin.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }
}
