import { Component } from "vue-property-decorator";
import Container from "./container";
import UIAlert from "@/components/UIAlert.vue";
import Admin from "@/store/admin/admin";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({ components: { UIAlert } })
export default class ContainerMobile extends Container {
  drawer = null;
  logoImagePath = null as string | null;
  businessName = null as string | null;

  async updated() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.logoImagePath = storage.logoImagePath ? storage.logoImagePath : null;
    this.businessName = storage.name ? storage.name : null;
  }

  get notifSubMenuItem() {
    return Admin.getNotifMenuItem.items;
  }

  get isTalkComment() {
    return /communication\/.+\/talk\/.+\/mobile/.test(this.$route.path);
  }

  get isShowNavigationDrawer() {
    return !this.isTalkComment;
  }

  get isShowAppBar() {
    return this.showMenu && !this.isTalkComment;
  }
}
