import Vue from "vue";
import ja from "vuetify/src/locale/ja";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#279bd8",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#f05252"
      }
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: { ja },
    current: "ja"
  }
});
