import { SessionStorage } from "@/store/session-storage";

const KEY_BUSINESS_SERVICE = "key_business_service";

/**
 * BusinessServiceStorageを管理するクラス
 */
export class BusinessServiceStorage {

  // 未ログインの画面はSessionStorageから取得
  static getSessionStorage() {
    return SessionStorage.getObject(KEY_BUSINESS_SERVICE);
  }

  static setSessionStorage(val) {
    SessionStorage.setObject(KEY_BUSINESS_SERVICE, val);
  }

  static removeSessionStorage() {
    SessionStorage.removeObject(KEY_BUSINESS_SERVICE);
  }

  // ログイン後の画面はlocalStorageから取得
  static getLocalStorage() {
    let storageData = localStorage.getItem(KEY_BUSINESS_SERVICE);
    return storageData ? JSON.parse(storageData) : {};
  }

  static setLocalStorage(val) {
    localStorage.setItem(KEY_BUSINESS_SERVICE, JSON.stringify(val));
  }

  static removeLocalStorage() {
    localStorage.removeItem(KEY_BUSINESS_SERVICE);
  }
}
