import { SideMenuItem, SideMenuResult } from "@/api/side-menu/response";

const KEY_MENU_RESULT = "key_menu_result";
const KEY_NOTIF_MENU_ITEM = "key_notif_menu_item";

/**
 * サイドメニュー関連のストレージ保存を管理するラッパークラス
 */
export class MenuStorage {
  /**
   * サイドメニュー（MenuResult）を保存します。
   *
   * @param menuResult MenuResult
   */
  static save(menuResult: SideMenuResult) {
    localStorage.setItem(KEY_MENU_RESULT, JSON.stringify(menuResult));
  }

  /**
   * 通知用のメニュー（MenuItem）を保存します。
   *
   * @param notifMenuItem MenuItem
   */
  static saveNotifMenuItem(notifMenuItem: SideMenuItem) {
    localStorage.setItem(KEY_NOTIF_MENU_ITEM, JSON.stringify(notifMenuItem));
  }

  /**
   * サイドメニュー（MenuResult）を取得します。
   *
   * @return SideMenuResult
   */
  static getMenuResult(): SideMenuResult {
    let storageData = localStorage.getItem(KEY_MENU_RESULT);
    return storageData ? JSON.parse(storageData) : {};
  }

  /**
   * 通知用のメニュー（MenuItem）を取得します。
   *
   * @return SideMenuItem
   */
  static getNotifMenuItem(): SideMenuItem {
    let storageData = localStorage.getItem(KEY_NOTIF_MENU_ITEM);
    return storageData ? JSON.parse(storageData) : {};
  }

  /**
   * メニュー関連を削除します。
   */
  static removeAll() {
    localStorage.removeItem(KEY_MENU_RESULT);
    localStorage.removeItem(KEY_NOTIF_MENU_ITEM);
  }
}
