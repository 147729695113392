import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";

/**
 * アラートのパラメータ
 */
export interface AlertParams extends SuccessAlert, ErrorAlert {
  type: "success" | "error";
}

/**
 * サクセスアラートのパラメータ
 */
export interface SuccessAlert {
  message: string;
  consumePath: string;
}

/**
 * エラーアラートのパラメータ
 */
export interface ErrorAlert {
  message: string;
  showReloadButton: boolean;
  messageSuccessBasic: string;
}

const MODULE_NAME = "common/flash";

/**
 * アラートに表示するアラートのパラメータを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Flash extends VuexModule {
  // state
  alertParams: AlertParams = {} as AlertParams;

  // getters
  get getMessage() {
    return this.alertParams.message || "";
  }

  get getMessageSuccessBasic() {
    return this.alertParams.messageSuccessBasic || "";
  }

  get getType() {
    return this.alertParams.type || "success";
  }

  get getShowAlertButton() {
    return this.alertParams.showReloadButton || false;
  }

  get getConsumePath() {
    return this.alertParams.consumePath || "";
  }

  // MutationActions
  @MutationAction
  async setSuccess(successAlert: SuccessAlert) {
    const alertParams = {
      message: successAlert.message,
      type: "success",
      showReloadButton: false,
      consumePath: successAlert.consumePath
    } as AlertParams;
    SessionStorage.setObject(MODULE_NAME, alertParams);
    return {
      alertParams
    };
  }

  @MutationAction
  async setSuccessNow(successAlert: SuccessAlert) {
    const alertParams = {
      message: successAlert.message,
      type: "success",
      showReloadButton: false,
      consumePath: ""
    } as AlertParams;
    SessionStorage.setObject(MODULE_NAME, alertParams);
    return {
      alertParams
    };
  }

  @MutationAction
  async setErrorNow(errorAlert: ErrorAlert) {
    const alertParams = {
      message: errorAlert.message,
      type: "error",
      showReloadButton: errorAlert.showReloadButton,
      consumePath: "",
      messageSuccessBasic: errorAlert.messageSuccessBasic
    } as AlertParams;
    SessionStorage.setObject(MODULE_NAME, alertParams);
    return {
      alertParams
    };
  }

  @MutationAction
  async restore(initial = {} as AlertParams) {
    const alertParams = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as AlertParams;
    return {
      alertParams
    };
  }

  @MutationAction
  async clear() {
    SessionStorage.removeObject(MODULE_NAME);
    return {
      alertParams: {} as AlertParams
    };
  }
}

export default getModule(Flash);
