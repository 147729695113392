







import Vue from "vue";
import Component from "vue-class-component";
import Container from "./views/Container.vue";
import ContainerMobile from "./views/ContainerMobile.vue";
import WindowSizeChecker from "@/utils/window-size-checker";

@Component({
  components: {
    Container,
    ContainerMobile
  }
})
export default class App extends Vue {
  get isPhoneAndTablet() {
    return WindowSizeChecker.isPhoneAndTablet(this);
  }
}
