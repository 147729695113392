import { LoginResponse } from "@/api/admin/response";

const KEY_ACCESS_TOKEN = "key_access_token";

const KEY_KBN = "key_kbn";

/**
 * ログイン関連のストレージ保存を管理するラッパークラス
 */
export class LoginStorage {
  /**
   * ログインのレスポンス（LoginResponse）からaccess_token/kbnを保存します。
   *
   * @param loginResponse LoginResponse
   * @param remember 永続保存するかどうか
   */
  static save(loginResponse: LoginResponse, remember: boolean) {
    if (remember) {
      localStorage.setItem(KEY_KBN, String(loginResponse.results.kbn));
      localStorage.setItem(KEY_ACCESS_TOKEN, loginResponse.results.accessToken);
    } else {
      sessionStorage.setItem(KEY_KBN, String(loginResponse.results.kbn));
      sessionStorage.setItem(
        KEY_ACCESS_TOKEN,
        loginResponse.results.accessToken
      );
    }
  }

  /**
   * KBNを取得します。
   *
   * @return KBN
   */
  static getKBN(): number | null {
    let storageData = localStorage.getItem(KEY_KBN);
    if (!storageData) {
      storageData = sessionStorage.getItem(KEY_KBN);
    }
    return storageData ? Number(storageData) : null;
  }

  /**
   * アクセストークンを取得します。
   *
   * @return アクセストークン
   */
  static getAccessToken(): string | null {
    let storageData = localStorage.getItem(KEY_ACCESS_TOKEN);
    if (!storageData) {
      storageData = sessionStorage.getItem(KEY_ACCESS_TOKEN);
    }
    return storageData;
  }

  /**
   * access_token/kbnを削除します。
   */
  static removeAll() {
    localStorage.removeItem(KEY_KBN);
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    sessionStorage.removeItem(KEY_KBN);
    sessionStorage.removeItem(KEY_ACCESS_TOKEN);
  }
}
