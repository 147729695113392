/**
 * テーブル操作があるリクエストの共通パラメータ
 */
export interface TableOptions {
  /** 開始ページ */
  page: number;
  /** 表示件数 */
  displayCount: number;
  /** ソート対象キー名（DBカラム名） */
  sortKey: string;
  /** ソートタイプ asc or desc */
  sortOrder: string;
}

/**
 * statusパラメータの有効
 */
export const STATUS_ENABLE = 1;

/**
 * sort_orderパラメータの降順
 */
export const SORT_ORDER_DESC = "desc";

/**
 * sort_orderパラメータの昇順
 */
export const SORT_ORDER_ASC = "asc";

export const DEFAULT_TABLE_OPTIONS: TableOptions = {
  page: 1,
  displayCount: 20,
  sortKey: "id",
  sortOrder: SORT_ORDER_DESC
};